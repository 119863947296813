// Classes
import { BaseUser } from "./base-user.class";
import { Group } from "./group.class";

export class User extends BaseUser {
    usaagoGroups: Group[] = null;

    constructor(userShape?: Partial<User>) {
        super(userShape);

        if (userShape != null) {
            if (userShape.usaagoGroups != null) {
                this.usaagoGroups = userShape.usaagoGroups.map((value) => new Group(value));
            }
        }
    }
}
