// Framework
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
// Global
import { backendURL } from "@src/app/global";
// Services
import { DataApiService } from "@src/app/modules/share/services/templates/data-api.service";

@Injectable({
    providedIn: "root"
})
export class SessionApiService extends DataApiService<object> {
    constructor(protected http: HttpClient) {
        super(backendURL + "session/", http, Object);
    }

    getSession(): Observable<void> {
        return this.http.get<void>(this.url, this.requestOptions);
        // return of(null);
    }

    createSession(loginObject: object): Observable<void> {
        return this.http.post<void>(this.url, loginObject, this.requestOptions);
        // return of(null);
    }

    /**
     * This method calls the backend and deletes the session.
     */
    deleteSession(): Observable<void> {
        return this.deleteOne("");
        // return of(null);
    }
}
